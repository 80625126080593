<template>
  <div class="columns-container">
    <div class="column is-half bg-gray">
      <slot name="left-half"></slot>
    </div>
    <div class="column is-half">
      <slot name="right-half"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotAuthorized'
}
</script>

<style lang="scss" scoped>
    .columns-container {
      display: flex;
      height: 100vh;
      margin: 0;

      .column {
        display: flex;
        justify-content: center;
        align-items: center;

        &.bg-gray {
          background-color: #FAFAFA;
        }
      }
    }
    
</style>