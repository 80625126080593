<template>
   <svg class="logo-svg" version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 966.5 639.4" style="enable-background:new 0 0 966.5 639.4;" xml:space="preserve">
    <g>
      <rect x="36" y="18.1" class="st0" width="617" height="180"/>
      <rect x="16" y="234.1" class="st0" width="539" height="180"/>
      <rect x="13" y="450.1" class="st0" width="924" height="180"/>
    </g>
    <g class="st1">
      <g id="Layer-1">
        <g>
          <defs>
            <rect id="SVGID_1_" y="0" width="966.5" height="639.4"/>
          </defs>
          <clipPath id="SVGID_2_">
            <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
          </clipPath>
          <g class="st2">
            <path class="st3" d="M538.1,388.9v-23.3h-56.5V252.5h-24.9v136.4H538.1z M234.6,252.5h-99.9v23.3h37.5v112.9
              c8.5,0.5,16.7,0.6,25,0V275.6h37.4V252.5z M124.6,258.8c-4.9-1.8-9.2-3.3-13.6-5c-11.6-4.3-23.6-5.5-35.9-3.9
              c-10.4,1.4-19.6,5.3-25.6,14.2c-10.2,15.2-7.4,35.5,6.6,47.8c9.3,8.2,19.8,14.9,29.6,22.5c4.2,3.3,8.7,6.5,12,10.6
              c7.6,9.4,2.4,19.4-8.6,21.7c-10.3,2.2-20.4-0.1-30-4.1c-4.7-1.9-9.1-4.3-13.9-6.6c-3.8,7.6-7.5,15.1-11.2,22.7
              c0.8,0.6,31.6,13.4,48.1,13.3c9.8-0.1,19.3-1.7,27.8-6.6c11-6.2,16.5-15.9,17.5-28.5c1.1-12.9-1.9-24.5-11.7-33.1
              c-9.5-8.3-20.2-15.3-30.3-23.1c-4.6-3.6-9.3-7.1-13.2-11.4c-3.8-4.1-3-12.7,5.1-14.9c8-2.1,15.6-0.6,23.1,1.9
              c4.2,1.4,8.2,3.4,12.7,5.2C116.9,274.3,120.5,267,124.6,258.8 M331.6,365.8h-56.5v-38.6h49.7v-22.7h-49.7v-29.3h55.5v-22.8
              h-80.3v136.2h81.3V365.8z M433.9,275.2v-22.7h-80.3v136.2h81.2V366h-56.1v-38.9H428v-22.8h-49.4v-29.1H433.9z M574.3,425.5
              L0.1,425.6l-0.1-213.4h574.3L574.3,425.5z"/>
            <path class="st4" d="M303.5,76.9c0-4.7,0.1-9.4,0-14.1c0-1.1-0.3-2.5-0.3-2.5l24.4,0.1c3.7,0.2,7.8,1.1,10.9,3
              c2.4,1.5,4.1,4.9,5,7.7c1.7,5.1,1.5,10.3-1.3,15.3c-3.4,6.1-8.8,7.3-14.7,7.7l-24.4-0.1c0,0,0.3-1.6,0.3-2.5
              C303.5,86.7,303.5,81.8,303.5,76.9"/>
            <path class="st4" d="M474.4,122.1c-11.1-0.5-22.4-0.6-35.8,0c7.1-14.5,12.8-29.2,17.9-43C462.1,92.8,467.5,107.6,474.4,122.1"/>
            <path class="st4" d="M570.3,151.7c1.1-0.6,2.3-0.9,2.9-1c2.6-0.6,71.4-0.2,71.4-0.2l-12.4,24c0,0-68.5,0-101.4,0H527
              c8-13.7,66.5-101.1,75-114.1c-0.6,0.5-5,1.3-16.3,1.3c-15.4,0-49.4-0.4-49.4-0.4l12.2-23.6c0,0,95.9-0.1,97-0.1
              C645.5,37.7,595.6,113.1,570.3,151.7 M496.8,174.2c-0.7-0.4-14.3-31.3-14.8-31.8c-0.7-0.7-50-0.7-50.7-0.4
              c-1,0.5-14.4,31.9-15,32.3c-0.6,0.4-27.2,0.3-27.5-0.1c-0.2-0.4,64.8-137.4,64.8-137.4l6.1,0c0,0,64.4,136.4,64.8,137.6
              C522.7,174.4,497.5,174.6,496.8,174.2 M354.7,174.5l-45.4-59l-6.1,0v59h-24.4l0.3-136.8c0,0,37.9-0.4,54.9,0.4
              c11.9,0.6,22.6,5.2,30,15.3c4.1,5.6,6.1,12.1,6.6,18.9c1,13.7-3.3,25.2-14.1,34.1c-4.8,3.9-10.5,5.9-16.5,6.8
              c-1,0.1-1.9,0.3-3.6,0.5l50.2,60.8L354.7,174.5z M202.6,174.5H197c0,0-62-135.7-62.3-136.8c9.7,0,29,0,29,0l34.9,87.7
              c0.1,0.3-0.1-0.3,1.3,4c0.8-3.3,0.7-3,1-3.9L236,37.7h28.3L202.6,174.5z M127.7,173.8H46.3V37.6h80.3v22.8H71.1v29.3h49.7v22.7
              H71.2V151h56.5V173.8z M0.1,0.2c0,0-0.2,209-0.1,212c1.9,0,672.9,0.2,672.9,0.2L673,0L0.1,0.2z"/>
            <path class="st5" d="M612.2,463.8h-24.6v136.6h24.6V463.8z M303,600.3h24.8V463.9H303V600.3z M355.7,463.8v136.5h81.1v-23.3
              h-56.5V463.8H355.7z M165.9,463.8c0,1.6-0.1,56.3,0.1,82.4c0,5.7,0.4,11.4,1.4,17c2,10.5,6.7,19.8,14.9,27
              c9.2,8.1,20.1,11.8,32.2,12.8c10.6,0.8,20.9,0.1,30.9-3.7c21.1-8,31.1-26.8,31.3-46.9c0.4-28.5,0.1-87.3,0.1-88.3
              c-8.5,0-16.8,0-25.3,0c0,1.3,0,52.2,0,76.6c0,4.6-0.2,9.2-0.9,13.7c-1.1,7-4.1,13.1-9.7,17.7c-7.9,6.4-17.2,7.4-26.6,5.7
              c-9.9-1.8-17.2-7.6-20.3-17.3c-1.6-5.1-2.5-10.7-2.5-16.1c-0.3-25.6-0.2-78.9-0.2-80.3H165.9z M452.5,600.5
              c1.7,0,30.2,0.4,43.2-0.1c8.2-0.3,16.6-1.2,24.5-3.1c14.6-3.5,26.7-11.4,35.4-23.8c7.4-10.6,10.9-22.5,11.6-35.3
              c0.7-13.1-0.5-26-6.3-38c-11.3-23.4-31.3-34-56.1-35.7c-16.7-1.2-48.7-0.4-52.4-0.3V600.5z M110.7,527.7c1.5-0.3,2.3-0.5,3-0.6
              c6.1-1,11.7-3.3,16.2-7.7c9.7-9.6,11.1-28.2,2.9-40.2c-7.4-10.7-18.2-14.9-30.7-15c-17.9-0.3-55.6,0.1-56.4,0.2v136.3
              c1.3,0,32.1,0.1,46.5,0c7-0.1,14-0.1,20.9-1c8-1,15.3-4.1,21.5-9.6c9-8.1,11.9-18.6,11.2-30.3c-0.8-14.3-7.6-24.3-21.5-28.8
              C120,529.6,115.7,528.9,110.7,527.7 M888.5,545c-1.4,11.8-7.1,21.3-17.7,27.6c-9.6,5.6-20.1,6.8-30.9,5.5
              c-12.6-1.5-23.1-6.9-30.4-17.4c-8.4-11.9-10.3-25.3-7.5-39.4c2.6-13.3,9.4-23.8,21.6-29.9c18.2-9.1,36.1-6.7,53.4,2.9
              c2.6,1.4,5,3.2,7.6,4.9c5.1-6.9,10.2-13.5,15.6-20.8c-0.8-0.5-14.9-9.1-21.9-11.9c-16.4-6.4-33.4-7.4-50.6-3.5
              c-21.3,4.8-37.4,16.6-46.6,36.6c-7.9,17.2-8.8,35-4.2,53.2c4.8,19.2,16,33.5,33.6,42.3c16.4,8.3,33.9,10,51.8,6.7
              c15.6-2.9,28.3-10.5,37.8-23.2c9.4-12.6,13.3-27.2,14.2-42.7c0.2-4.1,0-8.3,0-12.6h-67.8v22.1
              C853.6,545.5,881.2,545.2,888.5,545 M644,462.3h-4.1l0,138.1h24.8c0,0,0-54.4,0-81.4c0-1.7-0.3-3.2-0.7-4.7
              c3.1,3.8,73.1,74.8,86,87.7h4.6V463.9h-25.2v82.5c0,1.3,0.5,2.3,0.6,3.6L644,462.3z M966.5,425.5v213.9H0l0.1-213.8L966.5,425.5
              z"/>
            <path class="st5" d="M70.3,578.7v-37.5c0.2,0.3,1,0.5,1.8,0.5h28.7c1.7,0,3.4,0.4,5,0.6c8.2,0.9,13.3,7.8,13.7,15.8
              c0.5,10.5-4.2,17.1-12.6,19c-2.5,0.6-5.1,1-7.6,1c-7.4,0.1-19.1,0-26.5,0C71.1,578.1,70.7,578.4,70.3,578.7"/>
            <path class="st5" d="M70.4,519.2v-33.6c0.6,0.2,1,0.4,2.2,0.5c6.4,0,16.2-0.1,22.6,0.1c2.6,0.1,5.4,0.5,7.8,1.5
              c7.1,2.9,10.4,8.9,9.3,16.5c-1.1,8.1-6.2,13.6-13.8,14.3c-2.7,0.3-5.5,0.5-8.3,0.5c-3.2,0-14.9-0.1-18.1,0
              C71.2,518.9,70.7,519,70.4,519.2"/>
            <path class="st5" d="M477.4,577.8V487c7.3,0,14.6-0.1,21.9,0c18.7,0.3,33.6,9.8,38.8,27.4c5.1,17.5,4.3,34.7-7.7,49.6
              c-6.3,7.8-15.1,11.7-24.9,12.8C498.8,577.5,480.1,577.7,477.4,577.8"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Logo'
}
</script>

<style lang="scss">
  .logo-svg {
    width: 88px;
    .st0{fill:#FFFFFF;}
    .st2{clip-path:url(#SVGID_2_);}
    .st3{fill:#EE7717;}
    .st4{fill:#F8B72A;}
    .st5{fill:#58064A;}
  }
</style>