<template>
  <div class="form">
    <div class="logo">
      <logo />
    </div>
    <div class="title is-3-5 mb-1">Войти в систему</div>

    <form v-on:submit.prevent="login">
      <tnd-input
        class="form__input mt-2 mb-4"
        placeholder="Ваша почта"
        v-model="formData.email.value"
        :error="formData.email.error"
      >
        <template #before-input>
          <user-icon />
        </template>
      </tnd-input>
      <tnd-input
        class="form__input mb-3"
        placeholder="Ваш пароль"
        type="password"
        v-model="formData.password.value"
        :error="formData.password.error"
      >
        <template #before-input>
          <locker-icon />
        </template>
        <template #after-input>
          <a href="/forgot-password">Забыли?</a>
        </template>
      </tnd-input>
      <button
        @click.prevent="login"
        class="button is-normal main-button purple"
      >
        <span class="icon is-small">
          <user-icon color="#FFFFFF" />
        </span>
        <span>Войти в систему</span>
      </button>
    </form>

    <router-link to="/" class="common-link">Перейти на главную</router-link>
  </div>
</template>

<script>
import LockerIcon from "../icons/LockerIcon.vue";
import Logo from "../icons/Logo.vue";
import UserIcon from "../icons/UserIcon.vue";
import TndInput from "./TndInput.vue";

import authService from "../../services/auth.service";

export default {
  components: { Logo, TndInput, UserIcon, LockerIcon },
  data: () => ({
    formData: {
      email: { value: "", error: "" },
      password: { value: "", error: "" },
    },
  }),
  methods: {
    clearErrors() {
      Object.entries(this.formData).map(([fieldName, fieldData]) => {
        this.formData[fieldName].error = "";
      });
    },
    fillErrors(errorsData) {
      Object.entries(errorsData).map(([fieldName, errors]) => {
        this.formData[fieldName].error = errors[0];
      });
    },
    async login() {
      this.clearErrors();

      const data = {};
      Object.entries(this.formData).map(([fieldName, fieldData]) => {
        data[fieldName] = fieldData.value;
      });

      const { success, errors } = await authService.login(data);
      if (success) {
        this.$buefy.toast.open({
          message: "Вы успешно вошли в систему",
          type: "is-success",
          duration: 3000,
        });
        const query = this.$route.query
        if(query.register) {
          this.$router.push({ name: "Home", query});
        } else {
          this.$router.push({ name: "Home"});
        }
        return;
      }

      this.fillErrors(errors);
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  min-width: 50%;
  display: flex;
  flex-direction: column;

  .title {
    margin-top: 75px;
  }

  &__input:first {
    margin-top: 28px;
  }

  &__input + &__input {
    margin-top: 6px;
  }

  .common-link {
    margin-top: 77px;
  }
}
</style>