<template>  
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.8125 7.3125V3.97266C11.8125 3.22673 11.5162 2.51136 10.9887 1.98392C10.4613 1.45647 9.74592 1.16016 9 1.16016C8.25408 1.16016 7.53871 1.45647 7.01126 1.98392C6.48382 2.51136 6.1875 3.22673 6.1875 3.97266V7.3125" :stroke="color" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.9375 7.3125H5.0625C4.13052 7.3125 3.375 8.06802 3.375 9V15.1875C3.375 16.1195 4.13052 16.875 5.0625 16.875H12.9375C13.8695 16.875 14.625 16.1195 14.625 15.1875V9C14.625 8.06802 13.8695 7.3125 12.9375 7.3125Z" :stroke="color" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>

export default {
  name: 'LockerIcon',
  props: {
    color: {
      type: String,
      default: '#A01B5B'
    }
  }
}
</script>