<template>
  <not-authorised>
    <template #left-half>
      <login-form></login-form>
    </template>
    <template #right-half>
      <div class="right-unauthorised-block">
        <div class="title is-3-5">Ещё не зарегистрированы?</div>
        <p class="common-text">Пройдите быструю регистрацию в нашей системе</p>
        <router-link to="sign-up" class="button is-normal main-button purple">
          <span class="icon is-small">
            <user-add-icon/>
          </span>
          <span>Регистрация в системе</span>
        </router-link>
      </div>
    </template>
  </not-authorised>
</template>

<script>
import LoginForm from '../components/common/LoginForm.vue'
import NotAuthorised from '../components/common/NotAuthorised.vue'
import UserAddIcon from '../components/icons/UserAddIcon.vue'
export default {
  components: { NotAuthorised, LoginForm, UserAddIcon },
  name: 'Login'
}
</script>

<style lang="scss" scoped>

</style>